export default (url = null, payload = {}) => ({
    loading: false,
    message: null,
    haveError: false,
    showRestMessage: false,

    handle () {

        this.loading = true;
        this.haveError = false;

        axios.post(url, this._payload()).then(response => {

            if (response.data.success) {
                this._redirectTo()
            } else {
                this.haveError = true;
                this.message = response?.data?.message;
            }

        }).catch(error => {

            this.haveError = true;
            this.message = error.response.data.message;

            console.error(error.response.data.message)

        }).finally(() => {
            setTimeout(() => {
                this.loading = false
            }, 1000)
        })

    },

    init() {
        this._hydrate();
    },

    _hydrate() {

        const data = JSON.parse(payload)

        console.log('payload', data)
        console.log('resource for : url', url)

        for (const key in data) {
            this[key] = data[key]
        }
    },

    _payload() {

        const data = JSON.parse(payload)
        let values = {}
        const that = this

        for (const key in data) {
            values[key] = that[key]
        }

        console.log('payload', values)

        return values
    },

    _redirectTo() {
        return window.location.reload()
    }
})

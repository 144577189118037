require('./bootstrap');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

import resourceEdit from "./resources/edit.js";
import editPlanning from "./plannings";

Alpine.data('resourceEdit', resourceEdit)
Alpine.data('editPlanning', editPlanning)

Alpine.start();

(function($) {})(jQuery);
